<template>
  <v-dialog v-model="dialogIsOpen" persistent>
    <v-card>
      <v-card-title>Validation de l'émargement</v-card-title>
      <v-card-text>
        <div class="d-flex flex-column align-center">
          <span v-text="profile.lastname + ' ' + profile.firstname"></span>
          <span v-text="profile.email"></span>
          <span v-if="room" v-text="room.name"></span>
          <span v-text="new Date().toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 16)"></span>
        </div>
        <v-divider></v-divider>
        <div class="pt-4 pb-3">
          <div id="container"></div>
        </div>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="secondary" @click="$emit('close')">Annuler</v-btn>
        <v-btn color="error" @click="eraseSignature">Effacer</v-btn>
        <v-btn color="primary" @click="save">Valider</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default {
  props: ['profile', 'room', 'signature', 'profiles'],
  setup() {
    return {
      storage: getStorage()
    }
  },
  data() {
    return {
      dialogIsOpen: true,
      p5: null,
      p5Canvas: null
    }
  },
  mounted() {
    this.p5 = new p5(p => {
      p.setup = () => {
        this.p5Canvas = p.createCanvas(500, 300)
        p.background(255)
      }
      p.draw = () => {
        if (p.mouseIsPressed) {
          p.stroke(0)
          p.strokeWeight(2)
          p.line(p.mouseX, p.mouseY, p.pmouseX, p.pmouseY)
        }
      }
      p.eraseSignature = () => {
        p.background(255)
      }
    }, document.querySelector("#container"))
    document.querySelector("#container").addEventListener("touchmove", e => {
      e.preventDefault()
    })
  },
  methods: {
    eraseSignature() {
      this.p5.eraseSignature()
    },
    async save() {
      const validation = {
        user: this.profile.id,
        date: new Date().getTime()
      }

      const fileName = this.signature.id+'_adminSignature.png'
      const filePath = `signature/${this.room.id}/${this.signature.id}/${fileName}`
      const fileRef = ref(this.storage, filePath)

      this.p5Canvas.canvas.toBlob(async blob => {
        await uploadBytes(fileRef, blob)
        validation.filePath = filePath

        this.signature.validation = validation
        this.signature.isOpen = false

        this.profiles.forEach(profile => {
          if (!Object.keys(this.signature.signatures).includes(profile.id)) {
            this.signature.signatures[profile.id] = {
              filePath: null,
              date: null,
              ip: null,
              status: "Absent",
              adminStatus: null,
            }
          }
        })

        await this.signature.save()
        
        this.$emit('close')
        Swal.fire({
          title: "Émargement enregistré !",
          text: "Cet émargement ne pourra plus être modifié !",
          icon: "success",
          confirmButtonText: "Ok",
        })
      })
    }
  }
}
</script>

<style scoped>
* >>> .v-dialog {
  width: fit-content;
}
</style>