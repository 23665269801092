<template>
	<div v-if="new Array('Admin','SuperAdmin').includes(profile.role)">
		<v-card class="my-3">
            <v-card-title>Gerer l'émargement</v-card-title>
			<v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="4" class="pa-3" v-for="item in signatures" :key="item.id">
                        <v-card outlined :class="currentDate > item.endDate ? 'animate' : ''">
                            <v-card-title class="d-flex flex-row align-center justify-space-between">
                                <span v-text="new Date(item.date).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 16) + ' ( ' + Object.keys(item.signatures).length + '/' + profiles.length + ' )'"></span>
                                <span>
                                    <v-icon v-if="item.isOpen" color="success">mdi-lock-open-outline</v-icon>
                                    <v-icon v-else color="error">mdi-lock-outline</v-icon>
                                </span>
                            </v-card-title>
                            <v-card-text>
                                <v-progress-linear :value="Math.round((Object.keys(item.signatures).length/profiles.length)*100)"></v-progress-linear>
                                <v-row class="mt-2">
                                    <v-col cols="12" xl="6" class="d-flex align-center justify-center justify-xl-start py-1 px-1">
                                        <v-btn :disabled="!item.isOpen" color="secondary" width="100%" outlined @click="closeSignatures(item)">Valider l'émargement</v-btn>
                                        <!-- <v-btn v-else color="secondary" disabled width="100%" outlined >Emargement fermé</v-btn> -->
                                    </v-col>
                                    <v-col cols="12" xl="6" class="d-flex align-center justify-center justify-xl-end py-1 px-1">
                                        <v-btn color="primary" width="100%" outlined @click="openSignatureDisplay(item)">Consulter l'émargement</v-btn>
                                    </v-col>
                                    <v-col cols="12" class="d-flex align-center justify-center justify-xl-end py-1 px-1">
                                        <v-btn color="error" width="100%" outlined @click="deleteSignature(item)">Supprimer l'émargement</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-col cols="12" class="mt-5 px-3 py-0 d-flex justify-end">
                    <v-btn class="mx-1" color="primary" fab @click="openCreationDialog">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
			</v-card-text>
		</v-card>
        <!-- Create -->
        <v-dialog v-model="creationDialog" persistent max-width="500px">
            <v-card>
                <v-card-title class="d-flex flex-row align-center justify-space-between">
                    <span>
                        Nouvelle séance d'émargement
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-row class="pt-3">
                        <v-col cols="12" class="py-1">
                            <v-text-field type="date" label="Date de la séance" v-model="creationDate" />
                        </v-col>
                        <v-col cols="12" class="py-1">
                            <v-text-field type="time" label="Heure de début de la séance" v-model="creationTime" />
                        </v-col>
                        <v-col cols="12" class="py-1">
                            <v-text-field type="time" label="Heure de fin de la séance" v-model="creationEndTime" />
                        </v-col>
                        <v-col cols="12" class="py-1 mb-6">
                            <v-text-field type="number" min="0" label="Tolérence (en minutes)" v-model="tolerance" hide-details class="mb-1" />
                            <b class="error--text" v-if="tolerance < 5">Il est déconseillé d'utiliser une tolérance inférieure à 5 minutes. Les apprenants pourraient ne pas avoir le temps de signer.</b>
                            <p v-else class="mb-0">Tout émargement effectué en dehors de ce délai sera automatiquement considéré comme retardataire.</p>
                            
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center justify-center mt-6">
                        <v-btn class="mx-3" color="secondary" @click="creationDialog = false">Annuler</v-btn>
                        <v-btn class="mx-3" color="primary" @click="saveCreationDialog">Valider</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- check -->
        <v-dialog v-model="signaturesDisplay" persistent max-width="1000px">
            <v-card v-if="currentDisplayedSignature">
                <v-card-title class="d-flex flex-row align-center justify-space-between">
                    <span v-text="new Date(currentDisplayedSignature.date).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 16) + ' ( ' + Object.keys(currentDisplayedSignature.signatures).length + '/' + profiles.length + ' )'"></span>
                </v-card-title>
                <v-card-text>
                    <v-row class="pt-3">
                        <v-col cols="12" class="py-1">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>NOM</th>
                                        <th>Prenom</th>
                                        <th>Date</th>
                                        <th>Heure</th>
                                        <th>Delta</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="profile in profiles">
                                        <td v-text="profile.lastname"></td>
                                        <td v-text="profile.firstname"></td>
                                        <td>
                                            <span v-if="Object.keys(currentDisplayedSignature.signatures).includes(profile.id) && currentDisplayedSignature.signatures[profile.id].date != null" v-text="new Date(currentDisplayedSignature.signatures[profile.id].date).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 10)"></span>
                                        </td>
                                        <td>
                                            <span v-if="Object.keys(currentDisplayedSignature.signatures).includes(profile.id) && currentDisplayedSignature.signatures[profile.id].date != null" v-text="new Date(currentDisplayedSignature.signatures[profile.id].date).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }).slice(10, 16)"></span>
                                        </td>
                                        <td>
                                            <span v-if="Object.keys(currentDisplayedSignature.signatures).includes(profile.id) && currentDisplayedSignature.signatures[profile.id].date != null" v-text="Math.floor((currentDisplayedSignature.signatures[profile.id].date - currentDisplayedSignature.date)/(1000*60)) +'min'"></span>
                                        </td>
                                        <td>
                                            <v-menu v-if="currentDisplayedSignature.validation === null">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        <span v-if="Object.keys(currentDisplayedSignature.signatures).includes(profile.id) && currentDisplayedSignature.signatures[profile.id].adminStatus != null">
                                                            <v-chip class="pointer" outlined :color="displayStatus(currentDisplayedSignature.signatures[profile.id].adminStatus).color">{{ displayStatus(currentDisplayedSignature.signatures[profile.id].adminStatus).text }}</v-chip>
                                                        </span>
                                                        <span v-else-if="Object.keys(currentDisplayedSignature.signatures).includes(profile.id)">
                                                            <v-chip class="pointer" outlined :color="displayStatus(currentDisplayedSignature.signatures[profile.id].status).color">{{ displayStatus(currentDisplayedSignature.signatures[profile.id].status).text }}</v-chip>
                                                        </span>
                                                        <span v-else>
                                                            <v-chip class="pointer" outlined color="secondary">En attente</v-chip>
                                                        </span>
                                                    </span>
                                                </template>
                                                <v-list>
                                                    <v-list-item v-for="(item, index) in statusList(currentDisplayedSignature.signatures[profile.id])" :key="index" link @click="SetAdminStatus(currentDisplayedSignature, profile.id, item.value)">
                                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            <!-- can't be updated -->
                                            <div v-else>
                                                <span v-if="Object.keys(currentDisplayedSignature.signatures).includes(profile.id) && currentDisplayedSignature.signatures[profile.id].adminStatus != null">
                                                    <v-chip outlined :color="displayStatus(currentDisplayedSignature.signatures[profile.id].adminStatus).color">{{ displayStatus(currentDisplayedSignature.signatures[profile.id].adminStatus).text }}</v-chip>
                                                </span>
                                                <span v-else-if="Object.keys(currentDisplayedSignature.signatures).includes(profile.id)">
                                                    <v-chip outlined :color="displayStatus(currentDisplayedSignature.signatures[profile.id].status).color">{{ displayStatus(currentDisplayedSignature.signatures[profile.id].status).text }}</v-chip>
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <v-btn color="success" small text v-if="currentDisplayedSignature.signatures[profile.id] && currentDisplayedSignature.signatures[profile.id].filePath" @click="displaySignatureProof(currentDisplayedSignature, profile)">
                                                <v-icon>mdi-draw</v-icon>
                                                Voir la preuve d'émargement
                                            </v-btn>
                                            <span v-else>
                                                Aucune preuve d'émargement disponible
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center justify-center mt-10">
                        <v-btn class="mx-3" color="secondary" @click="cancelSignaturesDisplay">Annuler</v-btn>
                        <v-btn class="mx-3" color="primary" @click="saveSignaturesDisplay">Sauvegarder</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- signature -->
        <v-dialog v-model="DisplaySignatureProof" persistent max-width="500px">
            <v-card>
                <v-card-title class="d-flex flex-row align-center justify-center">
                    <span>
                        Preuve d'émargement
                    </span>
                </v-card-title>
                <v-card-text class="d-flex flex-column align-center justify-center" v-if="signatureProofData && currentRoom">
                    <div class="d-flex flex-column align-center justify-center">
                        <span v-text="signatureProofData.lastname + ' ' + signatureProofData.firstname"></span>
                        <span v-text="signatureProofData.email"></span>
                        <span v-text="currentRoom.name"></span>
                        <span v-text="new Date(signatureProofData.date).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 16)"></span>
                    </div>
                    <span class="py-3" style="width:100%">
                        <v-divider></v-divider>
                    </span>
                    <img :src="signatureProofData.url" style="max-width: 500px;"/>
                    <span class="py-3" style="width:100%">
                        <v-divider></v-divider>
                    </span>
                    <span class="pt-3">
                        <v-btn color="success" outlined class="mx-2" @click="closeSignatureProof">Ok</v-btn>
                    </span>
                </v-card-text>
            </v-card>
        </v-dialog>
        <SignModal :profile="profile" :room="currentRoom" :signature="currentDisplayedSignature" :profiles="profiles"
            v-if="signingForClosingModal"
            @close="signingForClosingModal = false; currentDisplayedSignature = null"
        />
	</div>
</template>

<script>
    import { getStorage, getDownloadURL, ref, deleteObject } from "firebase/storage"
    import Swal from 'sweetalert2/dist/sweetalert2.js'

    import Signature from "@/classes/Signature.js"
    import Profile from "@/classes/Profile.js"
    import SignModal from '@/components/Signatures/SignModal.vue'

	export default {
    name: "Signatures",
    props: ["user", "profile", "notifications", "config", "currentRoom"],
    components: { SignModal },
    setup() {
        return {
            storage: getStorage()
        };
    },
    data() {
        return {
            unsub: [],
            signatures: [],
            profiles: [],
            creationDialog: false,
            creationDate: '',
            creationTime: '',
            creationEndTime: '',
            tolerance: 5,
            signaturesDisplay: false,
            currentDisplayedSignature: null,
            DisplaySignatureProof: false,
            signatureProofData: null,
            signingForClosingModal: false,
            currentDate: new Date().getTime()
        }
    },
    created() {
        const currentDate = new Date()
        this.creationDate = currentDate.toISOString().split('T')[0]
        this.creationTime = currentDate.getHours() < 12 ? '08:00' : '14:00'
        this.creationEndTime = currentDate.getHours() < 12 ? '12:00' : '17:00'

        this.unsub.push(Profile.listenByRoomAndByRole(this.profile.currentRoom, "User", (profiles) => {
            this.profiles = profiles;
            this.profiles.sort((a, b) => {
                let sortingValue = a.lastname.localeCompare(b.lastname, "fr", { ignorePunctuation: true, sensitivity: "base" });
                if (sortingValue == 0) {
                    return a.firstname.localeCompare(b.firstname, "fr", { ignorePunctuation: true, sensitivity: "base" });
                }
                return sortingValue;
            });
        }));
        this.unsub.push(Signature.listenByRoom(this.profile.currentRoom, (signatures) => {
            signatures.sort((a, b) => a.date - b.date);
            this.signatures = signatures;
        }));
    },
    methods: {
        statusList(signature) {
            const list = [
                { text: "Présent", value: "Present" },
                { text: "Retard", value: "Late" },
                { text: "Absent", value: "Absent" },
                { text: "Excusé", value: "Excused" }
            ]

            if (!signature || signature.filePath === null) {
                list.push({ text: "En attente", value: null })
            }

            return list
        },
        openCreationDialog() {
            this.creationDialog = true
            const currentDate = new Date()
            this.creationDate = currentDate.toISOString().split('T')[0]
            this.creationTime = currentDate.getHours() < 12 ? '08:00' : '14:00'
            this.creationEndTime = currentDate.getHours() < 12 ? '12:00' : '17:00'
            this.tolerance = 5
        },
        saveCreationDialog() {
            if (this.creationDate.trim() == "" || this.creationTime.trim() == "")
                return;
            let date = new Date(this.creationDate + " " + this.creationTime).getTime();
            let endDate = new Date(this.creationDate + " " + this.creationEndTime).getTime()
            let signature = new Signature(null, date, endDate, this.profile.currentRoom, true, this.tolerance * 60 * 1000, {});
            signature.save().then(() => {
                this.creationDialog = false;
            });
        },
        SetAdminStatus(signature, profileId, newAdminStatus) {
            if (newAdminStatus === null) {
                delete signature.signatures[profileId]
            } else if (Object.keys(signature.signatures).includes(profileId)) {
                signature.signatures[profileId].adminStatus = newAdminStatus
            }
            else {
                signature.signatures[profileId] = {
                    filePath: null,
                    date: null,
                    ip: null,
                    status: null,
                    adminStatus: newAdminStatus,
                }
            }
        },
        openSignatureDisplay(signature) {
            this.currentDisplayedSignature = signature;
            this.signaturesDisplay = true;
        },
        cancelSignaturesDisplay() {
            this.signaturesDisplay = false;
            this.currentDisplayedSignature = null;
        },
        saveSignaturesDisplay() {
            this.currentDisplayedSignature.save();
            this.signaturesDisplay = false;
        },
        async displaySignatureProof(signature, profile) {
            let fileRef = ref(this.storage, signature.signatures[profile.id].filePath);
            let url = await getDownloadURL(fileRef);
            this.signatureProofData = {
                url: url,
                date: signature.signatures[profile.id].date,
                ip: signature.signatures[profile.id].ip,
                lastname: profile.lastname,
                firstname: profile.firstname,
                email: profile.email,
            };
            this.DisplaySignatureProof = true;
        },
        async closeSignatureProof() {
            this.signatureProofData = null;
            this.DisplaySignatureProof = false;
        },
        closeSignatures(signature) {
            Swal.fire({
                title: "Êtes-vous sûr ?",
                text: "Cette action fermera l'émargement, empêchera toutes les modifications et déclarera automatiquement tout utilisateur qui n'a pas encore émargé comme absent. (cette action est irréversible)",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.currentDisplayedSignature = signature
                    this.signingForClosingModal = true
                }
            })
        },
        async deleteSignature(signature) {
            Swal.fire({
                title: "Êtes-vous sûr ?",
                text: "Cette action supprimera l'émargement et toutes les données associées.",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    for (let profileId in signature.signatures) {
                        if (signature.signatures[profileId].filePath != null) {
                            let fileRef = ref(this.storage, signature.signatures[profileId].filePath);
                            await deleteObject(fileRef);
                        }
                    }
                    signature.delete();
                }
            });
        },
        displayStatus(status) {
            switch (status) {
                case "Present":
                    return { color: "success", text: "Présent" }
                case "Late":
                    return { color: "warning", text: "Retard" }
                case "Excused":
                    return { color: "accent", text: "Excusé" }
                case "Absent":
                    return { color: "error", text: "Absent" }
            }
        }
    },
    destroyed() {
        this.unsub.forEach((unsub) => unsub());
    }
}
</script>

<style scoped>
.animate {
    animation-duration: 2s;
    animation-name: lightBorder;
}

@keyframes lightBorder {
    45%, 65% {
        border: solid 1px var(--v-primary-base);
        box-shadow: var(--v-primary-base) 0px 0px 15px;
    }
}
</style>